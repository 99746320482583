import React, { Component, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useGoogleAnalytics, fetchAnalyticsData } from './utils/Analytics';

// Components
import Navbar from './components/Navbar';
import Slider from './components/Slider';
import Footer from './components/Footer';
import Sections from './components/Sections';
import Teachers from './components/Teachers';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';
import CookieConsentBar from './components/CookieConsentBar';

// Accounts
import LoginTeacherPage from './accounts/LoginTeacherPage';
import RegisterTeacherPage from './accounts/RegisterTeacherPage';

// Pages
import Contact from './pages/Contact';
import EncuestaPage from './pages/EncuestaPage';
import EncuestaDocentePage from './pages/EncuestaDocentePage';
import CalendarioPage from './pages/CalendarioPage';
import Preinscripciones from './pages/Preinscripciones';
import Tramites from './pages/Tramites';
import Organigrama from './pages/Organigrama';
import Conocenos from './pages/Conocenos';
import Efemerides from './pages/Efemerides';
import NewsSection from './pages/NewsSection';

import './App.css';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("ErrorBoundary caught an error", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Algo ha salido mal.</h1>;
    }

    return this.props.children; 
  }
}

const App = () => {
  useGoogleAnalytics();

  useEffect(() => {
    const getAnalyticsData = async () => {
      const data = await fetchAnalyticsData();
      console.log("Analytics Data:", data);
    };

    getAnalyticsData();
  }, []);

  return (
    <HelmetProvider>
      <div style={{ backgroundColor: '#d3d3d3', color: '#001f3f' }}>
        <Helmet>
          <title>Esc. Prim. Arturo Guerrero González T.M.</title>
          <meta name="description" content="Página oficial de la Escuela Primaria Arturo Guerrero González Turno Matutino." />
          <meta name="keywords" content="escuela, primaria, Arturo Guerrero González, educación, niños" />
          <meta name="author" content="Rodrigo Montor Velis" />
          <meta property="og:title" content="Esc. Prim. Arturo Guerrero González T.M." />
          <meta property="og:description" content="Página oficial de la Escuela Primaria Arturo Guerrero González Turno Matutino." />
          <meta property="og:image" content="https://primarturogro.edu.mx/uploads/LogoArturo.png" />
          <meta property="og:url" content="https://primarturogro.edu.mx" />
          <meta property="og:type" content="website" />
        </Helmet>
        <Router>
          <ErrorBoundary>
            <Navbar />
            <Routes>
              <Route path="/" element={<><Slider /><NewsSection /></>} />
              <Route path="/sections" element={<Sections />} />
              <Route path="/teachers" element={<Teachers />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/encuesta" element={<EncuestaPage />} />
              <Route path="/edoc" element={<EncuestaDocentePage />} />
              <Route path="/calendario" element={<CalendarioPage />} />
              <Route path="/privacidad" element={<PrivacyPolicy />} />
              <Route path="/terminos" element={<TermsAndConditions />} />
              <Route path="/login" element={<LoginTeacherPage />} />
              <Route path="/register" element={<RegisterTeacherPage />} />
              <Route path="/preinscripciones" element={<Preinscripciones />} />
              <Route path="/efemerides" element={<Efemerides />} />
              <Route path="*" element={<div>Página no encontrada</div>} />
            </Routes>
            <Footer />
            <CookieConsentBar />
          </ErrorBoundary>
        </Router>
      </div>
    </HelmetProvider>
  );
};

export default App;
