import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Navbar.css'; // Actualizar la ruta de importación
import sepIcon from '../assets/sep.png';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      setIsSubmenuOpen(false); // Cierra el submenú cuando se cierra el menú principal
    }
  };

  const toggleSubmenu = () => {
    setIsSubmenuOpen(!isSubmenuOpen);
  };

  return (
    <nav>
      <div className="logo-container">
        <Link to="/" onClick={toggleMenu}>
          <img src={sepIcon} alt="Logo" className="logo" />
        </Link>
        <button className="hamburger" onClick={toggleMenu}>
          &#9776;
        </button>
      </div>
      <ul className={isOpen ? 'nav-links open' : 'nav-links'}>
        <li><Link to="/" onClick={toggleMenu}>Inicio</Link></li>
        <li className="submenu">
          <span onClick={toggleSubmenu}>Secciones &#x25BC;</span>
          <ul className={isSubmenuOpen ? 'dropdown open' : 'dropdown'}>
            <li><Link to="/preinscripciones" onClick={toggleMenu}>Preinscripciones</Link></li>
            <li><Link to="/calendario" onClick={toggleMenu}>Calendario</Link></li>
            <li><Link to="/efemerides" onClick={toggleMenu}>Efemérides</Link></li>
          </ul>
        </li>
        <li><Link to="/contact" onClick={toggleMenu}>Contacto</Link></li>
        <li><Link to="/login" onClick={toggleMenu}>👤 Iniciar Sesión</Link></li>
      </ul>
    </nav>
  );
};

export default Navbar;
