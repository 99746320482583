import React from 'react';
import '../styles/UnderConstruction.css'; // Actualizar la ruta de importación

const Slider = () => {
/*
  return (
    <div className="construction-container">
      <div className="construction-content">
        <h1 className="construction-title">Bienvenidos a la Página Oficial de la Institución</h1>
        <p className="construction-text">La página sigue en construcción, por el momento se presenta solo información básica. Esperamos que en un futuro cuente con herramientas que aligeren la carga administrativa.</p>
        <div className="construction-message">
          <p className="construction-subtext">Gracias por tu paciencia.</p>
        </div>
      </div>
    </div>
  );
*/
};

export default Slider;