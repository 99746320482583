import React, { useState } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/RegisterTeacherPage.css';
import { GoogleLogin } from 'react-google-login';

const RegisterTeacherPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/teachers/create', { username, password });
            setMessage(response.data.message);
        } catch (error) {
            setMessage('Error creando la cuenta del docente');
        }
    };

    const handleGoogleSuccess = async (response) => {
        try {
            const res = await axios.post('/api/teachers/google-login', { token: response.tokenId });
            setMessage(res.data.message);
        } catch (error) {
            setMessage('Error creando la cuenta del docente con Google');
        }
    };

    const handleGoogleFailure = (response) => {
        setMessage('Error creando la cuenta del docente con Google');
    };

    return (
        <div className="d-flex justify-content-center align-items-center vh-100" style={{ backgroundColor: '#f0f0f0' }}>
            <div className="p-4 bg-white rounded shadow">
                <h1 className="text-center mb-4" style={{ fontSize: '1.5rem' }}>Registro de Docentes</h1>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label htmlFor="username" className="form-label" style={{ fontSize: '0.875rem' }}>Nombre de usuario:</label>
                        <input type="text" id="username" className="form-control" value={username} onChange={(e) => setUsername(e.target.value)} required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password" className="form-label" style={{ fontSize: '0.875rem' }}>Contraseña:</label>
                        <input type="password" id="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} required />
                    </div>
                    <button type="submit" className="btn btn-primary w-100">Registrar</button>
                </form>
                <div className="mt-3">
                    <GoogleLogin
                        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                        buttonText="Registrar con Google"
                        onSuccess={handleGoogleSuccess}
                        onFailure={handleGoogleFailure}
                        cookiePolicy={'single_host_origin'}
                        className="w-100"
                    />
                </div>
                {message && <p className="mt-3 text-center" style={{ fontSize: '0.875rem' }}>{message}</p>}
            </div>
        </div>
    );
};

export default RegisterTeacherPage;