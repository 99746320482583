import React from 'react';

const EncuestaDocentePage = () => {
  return (
    <div style={{ padding: '20px', backgroundColor: '#f0f0f0' }}>
      <h1>Encuesta para Docentes</h1>
      <p>Por favor, complete la siguiente encuesta para ayudarnos a mejorar la calidad educativa.</p>
      <form>
        <div>
          <label htmlFor="nombre">Nombre:</label>
          <input type="text" id="nombre" name="nombre" required />
        </div>
        <div>
          <label htmlFor="asignatura">Asignatura:</label>
          <input type="text" id="asignatura" name="asignatura" required />
        </div>
        <div>
          <label htmlFor="comentarios">Comentarios:</label>
          <textarea id="comentarios" name="comentarios" required></textarea>
        </div>
        <button type="submit">Enviar</button>
      </form>
    </div>
  );
};

export default EncuestaDocentePage;