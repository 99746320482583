import React from 'react';

const Teachers = () => {
  return (
    <div style={{ backgroundColor: '#d3d3d3', color: '#001f3f', padding: '20px' }}>
      <h1>Información sobre los Docentes</h1>
      <p>Aquí puedes encontrar información sobre nuestros docentes, sus especialidades y experiencia.</p>
      {/* Puedes agregar más contenido relacionado con los docentes aquí */}
    </div>
  );
};

export default Teachers;