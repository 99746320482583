import { gapi } from "gapi-script";
import { useEffect } from "react";
import credentials from "../config/credentials.json"; // Importa tu archivo JSON

export const useGoogleAnalytics = () => {
  useEffect(() => {
    const initClient = () => {
      const { client_id, api_key } = credentials.web; // Asegúrate de que los nombres coincidan con los del archivo JSON

      gapi.client
        .init({
          apiKey: api_key,
          clientId: client_id,
          scope: "https://www.googleapis.com/auth/analytics.readonly",
          discoveryDocs: [
            "https://analyticsdata.googleapis.com/$discovery/rest?version=v1",
          ],
        })
        .then(() => {
          console.log("Google API initialized");
        })
        .catch((error) => {
          console.error("Error initializing Google API", error);
        });
    };

    gapi.load("client:auth2", initClient);
  }, []);
};

export const fetchAnalyticsData = async () => {
  try {
    const response = await gapi.client.analyticsdata.properties.runReport({
      property: "properties/YOUR_PROPERTY_ID",
      requestBody: {
        dimensions: [{ name: "pagePath" }],
        metrics: [{ name: "activeUsers" }],
        dateRanges: [{ startDate: "7daysAgo", endDate: "today" }],
      },
    });
    return response.result;
  } catch (error) {
    console.error("Error fetching analytics data", error);
  }
};
