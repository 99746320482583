import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Footer.css'; // Ruta de importación verificada

const Footer = () => {
  return (
    <footer className="footer">
      <p>&copy; {new Date().getFullYear()} Escuela Primaria Arturo Guerrero González T. M. Todos los derechos reservados.</p>
      <Link to="/terminos" className="footer-link">Términos y Condiciones</Link>
    </footer>
  );
};

export default Footer;