import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/CalendarioPage.css'; // Importar el archivo CSS

const CalendarioPage = () => {
  return (
    <div className="calendario-main-container">
      <Helmet>
        <title>Calendario Escolar</title>
        <meta property="og:title" content="Calendario Escolar" />
        <meta property="og:description" content="Consulta el calendario escolar oficial." />
        <meta property="og:image" content="https://primarturogro.edu.mx/uploads/LogoArturo.png" />
        <meta property="og:url" content="https://primarturogro.edu.mx/calendario" />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="container-fluid header-mg">
        <div className="row">
          <div className="col-md-8">
            <div className="row">
              <div className="col-sm-8 col-sm-offset-2">
                <img className="img-responsive" src="https://calendarioescolar.sep.gob.mx/storage/recursos/2024/06/aZ3K3yKVew-a01.png" alt="Calendario 2024-2025 - Página 1" />
              </div>
            </div>
            <div className="row hidden-xs">
              <br />
              <div className="col-sm-12">
                <img className="img-responsive" src="https://calendarioescolar.sep.gob.mx/storage/recursos/2024/06/P8Q5cVeQfl-a02.png" alt="Calendario 2024-2025 - Página 2" />
              </div>
              <br />
            </div>
            <div className="col-xs-10 col-xs-offset-1 visible-xs">
              <br />
              <span style={{ color: '#a22244', fontWeight: 'bold', letterSpacing: '.3em' }}>EDUCACIÓN PREESCOLAR, PRIMARIA Y SECUNDARIA</span>
              <h1 style={{ color: '#691b31', fontWeight: 'bold' }}>Calendario Escolar <span style={{ color: '#bc955a' }}>2024-2025</span></h1>
              <p className="small">Vigente para las escuelas públicas y particulares incorporadas al Sistema Educativo Nacional en los Estados Unidos Mexicano</p>
              <br />
            </div>
            <br />
            <img className="img-responsive" src="https://calendarioescolar.sep.gob.mx/storage/recursos/2024/06/iD2s26k83H-a03.png" alt="Calendario 2024-2025 - Página 3" />
          </div>
          <div className="col-sm-4 hidden-sm hidden-xs">
            <img className="img-responsive" src="https://calendarioescolar.sep.gob.mx/storage/recursos/2024/06/4SqLTSlM47-a04.jpg" alt="Calendario 2024-2025 - Página 4" />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 col-md-4 col-lg-2">
            <img className="img-responsive" src="https://calendarioescolar.sep.gob.mx/storage/recursos/2024/06/AJcMcOIYqi-b01.png" alt="Calendario 2024-2025 - Página 5" />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-2">
            <img className="img-responsive" src="https://calendarioescolar.sep.gob.mx/storage/recursos/2024/06/bBzSTOh6ds-b02.png" alt="Calendario 2024-2025 - Página 6" />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-2">
            <img className="img-responsive" src="https://calendarioescolar.sep.gob.mx/storage/recursos/2024/06/fwVODn3sn3-b03.png" alt="Calendario 2024-2025 - Página 7" />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-2">
            <img className="img-responsive" src="https://calendarioescolar.sep.gob.mx/storage/recursos/2024/06/SYInG7CZcn-b04.png" alt="Calendario 2024-2025 - Página 8" />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-2">
            <img className="img-responsive" src="https://calendarioescolar.sep.gob.mx/storage/recursos/2024/06/BUYeKOJfHT-b05.png" alt="Calendario 2024-2025 - Página 9" />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-2">
            <img className="img-responsive" src="https://calendarioescolar.sep.gob.mx/storage/recursos/2024/06/6W8kHQSMmA-b06.png" alt="Calendario 2024-2025 - Página 10" />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-6 col-md-4 col-lg-2">
            <img className="img-responsive" src="https://calendarioescolar.sep.gob.mx/storage/recursos/2024/06/b2M9BcZHvA-c01.png" alt="Calendario 2024-2025 - Página 11" />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-2">
            <img className="img-responsive" src="https://calendarioescolar.sep.gob.mx/storage/recursos/2024/06/MSI0EAPJ8X-c02.png" alt="Calendario 2024-2025 - Página 12" />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-2">
            <img className="img-responsive" src="https://calendarioescolar.sep.gob.mx/storage/recursos/2024/06/uhCZAVjGY3-c03.png" alt="Calendario 2024-2025 - Página 13" />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-2">
            <img className="img-responsive" src="https://calendarioescolar.sep.gob.mx/storage/recursos/2024/06/PHAH9cYP9j-c04.png" alt="Calendario 2024-2025 - Página 14" />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-2">
            <img className="img-responsive" src="https://calendarioescolar.sep.gob.mx/storage/recursos/2024/06/zyom4p2rfh-c05.png" alt="Calendario 2024-2025 - Página 15" />
          </div>
          <div className="col-sm-6 col-md-4 col-lg-2">
            <img className="img-responsive" src="https://calendarioescolar.sep.gob.mx/storage/recursos/2024/06/Jq3gsr9nVq-c06.png" alt="Calendario 2024-2025 - Página 16" />
          </div>
        </div>
        <div className="row">
          <div className="col-sm-8">
            <br /><br />
            <img className="img-responsive" src="https://calendarioescolar.sep.gob.mx/storage/recursos/2024/06/3VAStt8LfM-anotaciones.jpg" alt="Calendario 2024-2025 - Página 17" />
          </div>
          <div className="col-sm-4">
            <img className="img-responsive" src="https://calendarioescolar.sep.gob.mx/storage/recursos/2024/06/r3gXTpqpIS-d02.png" alt="Calendario 2024-2025 - Página 18" />
          </div>
        </div>
        <img className="img-responsive" src="https://calendarioescolar.sep.gob.mx/storage/recursos/2024/06/2e7AA6E7ro-f01.png" alt="Calendario 2024-2025 - Página 19" />
      </div>
    </div>
  );
};

export default CalendarioPage;