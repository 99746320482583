import React from 'react';
import '../styles/UnderConstruction.css'; // Actualizar la ruta de importación

const Sections = () => {
  return (
    <div className="construction-container">
      <div className="construction-content">
        <h1 className="construction-title">Sitio en Construcción</h1>
        <p className="construction-text">Estamos trabajando duro para traerte una nueva experiencia. ¡Vuelve pronto!</p>
        <div className="construction-message">
          <p className="construction-subtext">Gracias por tu paciencia.</p>
        </div>
      </div>
    </div>
  );
};

export default Sections;