import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/NewsSection.css';

const NewsSection = () => {
    return (
      <div className="news-section">
        <Helmet>
          <title>Esc. Prim. Arturo Guerrero González T.M.</title>
          <meta name="description" content="Página oficial de la Escuela Primaria Arturo Guerrero González Turno Matutino." />
          <meta name="keywords" content="escuela, primaria, Arturo Guerrero González, educación, niños" />
          <meta name="author" content="Rodrigo Montor Velis" />
          <meta property="og:title" content="Esc. Prim. Arturo Guerrero González T.M." />
          <meta property="og:description" content="Página oficial de la Escuela Primaria Arturo Guerrero González Turno Matutino." />
          <meta property="og:image" content="https://primarturogro.edu.mx/uploads/LogoArturo.png" />
          <meta property="og:url" content="https://primarturogro.edu.mx" />
          <meta property="og:type" content="website" />
        </Helmet>
        <h2>Últimas Noticias 📰</h2>
        <div className="news-cards-container">
          <div className="news-card news-card-important">
            <h3>✏️ Preinscripciones</h3>
            <p>Conoce los requisitos, fechas y enlaces para el proceso de preinscripción 2025-2026.</p>
            <button onClick={() => window.location.href='/preinscripciones'}>
              Ver más
            </button>
          </div>
          <div className="news-card">
            <h3>📅 Calendario Oficial</h3>
            <p>Consulta las fechas del Calendario Oficial Federal para el Ciclo Escolar 2024-2025.</p>
            <button onClick={() => window.location.href='/calendario'}>
              Ver más
            </button>
          </div>
          <div className="news-card">
            <h3>📞 Contáctanos</h3>
            <p>Ante cualquier duda o información por favor contáctanos con nosotros, podrás obtener más información de nuestra ubicación e información.</p>
            <button onClick={() => window.location.href='/contact'}>
              Ver más
            </button>
          </div>
        </div>
        <div className="facebook-feed">
          <h3>Facebook Feed 📱</h3>
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <iframe
              src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fprimarturogro&tabs=timeline&width=500&height=600&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
              width="500"
              height="600"
              scrolling="no"
              frameBorder="0"
              allowFullScreen={true}
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            ></iframe>
          </div>
        </div>
      </div>
    );
};

export default NewsSection;