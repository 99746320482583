import React from 'react';

const TermsAndConditions = () => {
  return (
    <div style={{ padding: '20px', backgroundColor: '#f0f0f0', color: '#333' }}>
      <h1>Términos y Condiciones</h1>
      <p>
        Bienvenido a la página oficial de la Escuela Primaria Arturo Guerrero González Turno Matutino. Al utilizar este sitio, aceptas cumplir con los siguientes términos y condiciones. Por favor, lee detenidamente este documento antes de utilizar los servicios ofrecidos en este sitio web.
      </p>

      <h2>Uso del Sitio</h2>
      <p>
        Este sitio está destinado a fines educativos y de información para la comunidad escolar. No se permite el uso comercial ni la reproducción total o parcial de los contenidos sin autorización previa por escrito. Cualquier uso indebido será sancionado conforme a los artículos 6 y 7 de la Constitución Política de los Estados Unidos Mexicanos y el Código Penal Federal.
      </p>
      <p>
        Los usuarios deben abstenerse de realizar actividades que puedan dañar, interrumpir o interferir con el funcionamiento normal del sitio, incluyendo pero no limitándose a intentos de hackeo, difusión de malware o uso excesivo de recursos.
      </p>

      <h2>Propiedad Intelectual</h2>
      <p>
        Todos los contenidos de este sitio, incluyendo pero no limitándose a textos, imágenes, gráficos, logotipos, audios y videos, son propiedad exclusiva de la institución y están protegidos por derechos de autor conforme a la Ley Federal del Derecho de Autor. Está estrictamente prohibida la reproducción, distribución, publicación o modificación de los mismos sin autorización expresa.
      </p>
      <p>
        El uso no autorizado de estos materiales puede resultar en sanciones civiles y penales de acuerdo con la legislación vigente en México.
      </p>

      <h2>Protección de Datos Personales</h2>
      <p>
        La institución se compromete a proteger la privacidad de los usuarios. Cualquier información personal recopilada será utilizada exclusivamente para los fines educativos y administrativos de la escuela, en conformidad con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares.
      </p>
      <p>
        De acuerdo con el artículo 16 de la Constitución Política de los Estados Unidos Mexicanos, garantizamos el acceso, rectificación, cancelación y oposición (ARCO) de tus datos personales. Para ejercer estos derechos, puedes contactarnos a través de la sección de contacto del sitio.
      </p>

      <h2>Uso de Cookies</h2>
      <p>
        Este sitio utiliza cookies para mejorar la experiencia del usuario, analizar el tráfico y personalizar el contenido. Al navegar en este sitio, aceptas el uso de cookies conforme a nuestra política de privacidad. Puedes desactivar las cookies desde la configuración de tu navegador; sin embargo, esto podría afectar la funcionalidad del sitio.
      </p>
      <p>
        Las cookies no recopilan información personal identificable sin tu consentimiento previo.
      </p>

      <h2>Uso de la Imagen Pública</h2>
      <p>
        Las imágenes y videos publicados en este sitio que incluyan a estudiantes, maestros y personal de la escuela se utilizan exclusivamente con fines educativos, informativos y de promoción institucional. Si deseas solicitar la eliminación de alguna imagen o video en los que aparezcas tú o tus hijos, por favor contáctanos directamente.
      </p>
      <p>
        Este uso está regulado por el artículo 87 de la Ley Federal del Derecho de Autor.
      </p>

      <h2>Responsabilidad Limitada</h2>
      <p>
        La escuela no se hace responsable de los daños o perjuicios derivados del uso del sitio web, incluyendo pero no limitándose a errores técnicos, interrupciones en el servicio o inexactitudes en los contenidos. El uso de este sitio es bajo tu propia responsabilidad.
      </p>
      <p>
        Nos reservamos el derecho de suspender, modificar o descontinuar cualquier funcionalidad del sitio sin previo aviso.
      </p>

      <h2>Enlaces a Sitios Externos</h2>
      <p>
        Este sitio puede contener enlaces a páginas externas. No nos hacemos responsables del contenido, políticas de privacidad o prácticas de dichos sitios. La inclusión de enlaces no implica respaldo ni asociación con los mismos.
      </p>

      <h2>Jurisdicción y Ley Aplicable</h2>
      <p>
        Estos términos y condiciones se interpretarán y regirán de acuerdo con las leyes aplicables en los Estados Unidos Mexicanos. Cualquier conflicto derivado del uso de este sitio será sometido a la jurisdicción de los tribunales competentes en México.
      </p>
      <p>
        En caso de discrepancia entre las versiones en español y otros idiomas de estos términos y condiciones, prevalecerá la versión en español.
      </p>

      <h2>Modificaciones</h2>
      <p>
        Nos reservamos el derecho de modificar estos términos y condiciones en cualquier momento y sin previo aviso. Las modificaciones serán efectivas una vez publicadas en esta página. Te recomendamos revisar esta sección periódicamente.
      </p>

      <h2>Contacto</h2>
      <p>
        Si tienes preguntas, comentarios o inquietudes sobre estos términos y condiciones, puedes contactarnos a través del formulario de contacto disponible en este sitio.
      </p>
    </div>
  );
};

export default TermsAndConditions;
