import React, { useEffect, useState } from 'react';
import '../styles/Contact.css'; // Actualizar la ruta de importación

const Contact = () => {
  const [status, setStatus] = useState('');

  useEffect(() => {
    const checkOpenStatus = () => {
      const now = new Date();
      const utcHour = now.getUTCHours();
      const utcDay = now.getUTCDay();
      const gmt7Hour = utcHour - 7;
      const isOpen = (gmt7Hour >= 8 && gmt7Hour < 12) || (gmt7Hour === 12 && now.getUTCMinutes() <= 30);
      const isWeekday = utcDay >= 1 && utcDay <= 5;
      setStatus(isOpen && isWeekday ? 'Abierto' : 'Cerrado');
    };

    checkOpenStatus();
    const intervalId = setInterval(checkOpenStatus, 60000); // Check every minute

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  return (
    <div style={{ padding: '20px', backgroundColor: '#f0f0f0', color: '#333' }}>
      <h1><b>Contacto</b></h1>
      <p>Si tienes alguna pregunta, no dudes en ponerte en contacto con nosotros.</p>
      <div>
        <p><b>Correo Electrónico:</b> <a href="mailto:primarturogro@hotmail.com" style={{ textDecoration: 'none', color: '#333' }}>primarturogro@hotmail.com</a></p>
        <p><b>Teléfono:</b> (624) 143-6709</p>
        <p><b>Dirección:</b> Rocas s/n esq. Erizo y Dragonero, Cabo San Lucas, Mexico</p>
        <p><b>Horario:</b> 8:00 a.m. - 12:30 p.m. <span id="status" style={{ color: status === 'Abierto' ? 'green' : 'red' }}>({status})</span></p>
      </div>
      <div style={{ marginTop: '20px' }}>
        <iframe
          title="Ubicación en Google Maps"
          src="https://www.google.com/maps/embed?pb=!4v1698781234567!6m8!1m7!1s0x86af4b34819c1017:0xfc11e08319d6c1b6!2m2!1d22.8920092!2d-109.9408935!3f0!4f0!5f0!3m2!1sen!2smx!4v1698781234567!5m2!1sen!2smx"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
};

export default Contact;