import React from 'react';
import '../styles/EncuestaPage.css';

const EncuestaPage = () => {
  return (
    <div className="encuesta-container">
      <h1 className="encuesta-title">Encuesta Educativa</h1>
      <p className="encuesta-description">Por favor, complete la siguiente encuesta para ayudarnos a mejorar.</p>
      <form>
        <div className="encuesta-question">
          <label>1. ¿Cómo calificaría la calidad de la educación?</label><br />
          <select>
            <option value="excelente">Excelente</option>
            <option value="bueno">Bueno</option>
            <option value="regular">Regular</option>
            <option value="malo">Malo</option>
          </select>
        </div>
        <div className="encuesta-question">
          <label>2. ¿Qué aspectos le gustaría mejorar?</label><br />
          <textarea rows="4" cols="50"></textarea>
        </div>
        <button type="submit" className="encuesta-submit">
          Enviar
        </button>
      </form>
    </div>
  );
};

export default EncuestaPage;