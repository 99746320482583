import React, { useState } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/LoginTeacherPage.css';
import { GoogleLogin } from 'react-google-login';

const LoginTeacherPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('/api/teachers/login', { username, password });
            setMessage(response.data.message);
        } catch (error) {
            setMessage('Ha ocurrido un error durante el inicio de sesión.');
        }
    };

    const handleLoginSuccess = async (response) => {
        try {
            const res = await axios.post('/api/teachers/google-login', { token: response.tokenId });
            setMessage(res.data.message);
        } catch (error) {
            setMessage('Ha ocurrido un error durante el inicio de sesión.');
        }
    };

    const handleLoginFailure = (response) => {
        setMessage('Ha ocurrido un error durante el inicio de sesión.');
    };

    return (
        <div>
            <Helmet>
                <title>Iniciar Sesión</title>
                <meta property="og:title" content="Iniciar Sesión" />
                <meta property="og:description" content="Inicia sesión en el portal de maestros." />
                <meta property="og:image" content="https://primarturogro.edu.mx/uploads/LogoArturo.png" />
                <meta property="og:url" content="https://primarturogro.edu.mx/login" />
                <meta property="og:type" content="website" />
            </Helmet>
            <div className="d-flex justify-content-center align-items-center vh-100" style={{ backgroundColor: '#f0f0f0' }}>
                <div className="p-4 bg-white rounded shadow">
                    <h1 className="text-center mb-4" style={{ fontSize: '1.5rem' }}>Inicio de Sesión</h1>
                    <form onSubmit={handleSubmit}>
                        <div className="mb-3">
                            <label htmlFor="username" className="form-label" style={{ fontSize: '0.875rem' }}>Nombre de usuario:</label>
                            <input type="text" id="username" className="form-control" value={username} onChange={(e) => setUsername(e.target.value)} required />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label" style={{ fontSize: '0.875rem' }}>Contraseña:</label>
                            <input type="password" id="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} required />
                        </div>
                        <button type="submit" className="btn btn-primary w-100">Iniciar Sesión</button>
                    </form>
                    <div className="mt-3">
                        <GoogleLogin
                            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                            buttonText="Iniciar sesión con Google"
                            onSuccess={handleLoginSuccess}
                            onFailure={handleLoginFailure}
                            cookiePolicy={'single_host_origin'}
                            className="w-100"
                        />
                    </div>
                    {message && <p className="mt-3 text-center" style={{ fontSize: '0.875rem' }}>{message}</p>}
                </div>
            </div>
        </div>
    );
};

export default LoginTeacherPage;