import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/Efemerides.css'; // Actualizar la ruta de importación

const Efemerides = () => (
    <div className="efemerides-container">
        <Helmet>
            <title>Efemérides</title>
            <meta property="og:title" content="Efemérides" />
            <meta property="og:description" content="Lista de efemérides importantes para el ciclo escolar." />
            <meta property="og:image" content="https://primarturogro.edu.mx/uploads/LogoArturo.png" />
            <meta property="og:url" content="https://primarturogro.edu.mx/efemerides" />
            <meta property="og:type" content="website" />
        </Helmet>
        <h1>Efemérides</h1>
        <p>A continuación, se presentan las efemérides sugeridas para el Ciclo Escolar:</p>
        <div className="efemerides-content">
            <h2>Septiembre</h2>
            <ul>
                <li>1 de septiembre: Inicio del mes patrio y entrega del Informe de Gobierno del Presidente de la República.</li>
                <li>4 de septiembre: Fundación de la Ciudad de México (Tenochtitlán) en 1521.</li>
                <li>8 de septiembre: Día Internacional de la Alfabetización.</li>
                <li>8 de septiembre: Natalicio de Josefa Ortiz de Domínguez (1768).</li>
                <li>11 de septiembre: Fallecimiento de Vicente Guerrero (1831).</li>
                <li>13 de septiembre: Gesta Heroica de los Niños Héroes de Chapultepec (1847).</li>
                <li>14 de septiembre: Incorporación de Chiapas a México (1824).</li>
                <li>15 de septiembre: Grito de Independencia.</li>
                <li>16 de septiembre: Inicio de la Guerra de Independencia (1810).</li>
                <li>19 de septiembre: Aniversario de los sismos de 1985 y 2017 en México.</li>
                <li>20 de septiembre: Fallecimiento de Manuel Tolsá (1816), arquitecto y escultor.</li>
                <li>23 de septiembre: Fundación de la Liga Comunista 23 de Septiembre (1973).</li>
                <li>27 de septiembre: Consumación de la Independencia de México (1821).</li>
                <li>28 de septiembre: Fundación del Primer Imperio Mexicano (1821).</li>
                <li>30 de septiembre: Natalicio de José María Morelos y Pavón (1765).</li>
            </ul>
            <h2>Octubre</h2>
            <ul>
                <li>1 de octubre: Transmisión del poder presidencial en México (cada seis años).</li>
                <li>2 de octubre: Conmemoración de la Matanza de Tlatelolco (1968).</li>
                <li>4 de octubre: Fundación de San Francisco de Campeche (1540).</li>
                <li>7 de octubre: Natalicio de Francisco I. Madero (1873).</li>
                <li>12 de octubre: Día de la Raza.</li>
                <li>15 de octubre: Fundación de Monterrey (1596).</li>
                <li>19 de octubre: Día Mundial del Cáncer de Mama.</li>
                <li>22 de octubre: Día Mundial de la Medicina Tradicional.</li>
                <li>23 de octubre: Natalicio de Ignacio Zaragoza (1829).</li>
                <li>24 de octubre: Día de las Naciones Unidas.</li>
                <li>30 de octubre: Fundación de la Real y Pontificia Universidad de México (1551).</li>
            </ul>
            <h2>Noviembre</h2>
            <ul>
                <li>1 de noviembre: Día de Todos los Santos.</li>
                <li>2 de noviembre: Día de Muertos.</li>
                <li>7 de noviembre: Fundación de la Villa de Colima (1523).</li>
                <li>14 de noviembre: Día Mundial de la Diabetes.</li>
                <li>16 de noviembre: Fundación de la Universidad Nacional Autónoma de México (UNAM) en 1910.</li>
                <li>18 de noviembre: Aniversario del levantamiento en armas de los hermanos Serdán en Puebla (1910).</li>
                <li>20 de noviembre: Aniversario de la Revolución Mexicana (1910).</li>
                <li>22 de noviembre: Día del Músico (Santa Cecilia).</li>
                <li>23 de noviembre: Día de la Armada de México.</li>
                <li>30 de noviembre: Fallecimiento de Sir Winston Churchill (1953).</li>
            </ul>
            <h2>Diciembre</h2>
            <ul>
                <li>2 de diciembre: Creación de la Secretaría de la Defensa Nacional (1937).</li>
                <li>7 de diciembre: Día Internacional de la Aviación Civil.</li>
                <li>9 de diciembre: Fundación de la Ciudad de México por Hernán Cortés (1531).</li>
                <li>12 de diciembre: Día de la Virgen de Guadalupe.</li>
                <li>15 de diciembre: Inicio de sesiones del Congreso de la Unión (cada año).</li>
                <li>18 de diciembre: Día Internacional del Migrante.</li>
                <li>24 de diciembre: Nochebuena.</li>
                <li>25 de diciembre: Navidad.</li>
                <li>28 de diciembre: Día de los Santos Inocentes.</li>
                <li>31 de diciembre: Fin de Año.</li>
            </ul>
            <h2>Enero</h2>
            <ul>
                <li>1 de enero: Año Nuevo.</li>
                <li>6 de enero: Día de Reyes.</li>
                <li>7 de enero: Matanza de Cananea (1906).</li>
                <li>13 de enero: Fundación de la Real Audiencia de la Nueva España (1528).</li>
                <li>21 de enero: Natalicio de Ignacio Allende (1769).</li>
                <li>24 de enero: Día Internacional de la Educación.</li>
                <li>25 de enero: Natalicio de Manuel Acuña, poeta romántico mexicano (1849).</li>
                <li>30 de enero: Día Escolar de la No Violencia y la Paz.</li>
                <li>31 de enero: Muerte de Juan Escutia en la defensa del Castillo de Chapultepec (1847).</li>
            </ul>
            <h2>Febrero</h2>
            <ul>
                <li>2 de febrero: Día de la Candelaria.</li>
                <li>5 de febrero: Promulgación de la Constitución de 1917.</li>
                <li>9 de febrero: Aniversario de la Decena Trágica (1913).</li>
                <li>14 de febrero: Día del Amor y la Amistad.</li>
                <li>15 de febrero: Día Internacional del Cáncer Infantil.</li>
                <li>19 de febrero: Día del Ejército Mexicano.</li>
                <li>22 de febrero: Fallecimiento de Francisco I. Madero (1913).</li>
                <li>23 de febrero: Batalla de Río San Gabriel (1847).</li>
                <li>24 de febrero: Día de la Bandera.</li>
                <li>27 de febrero: Nacimiento del poeta mexicano Manuel Acuña (1849).</li>
            </ul>
            <h2>Marzo</h2>
            <ul>
                <li>1 de marzo: Fundación de la Villa Rica de la Vera Cruz (1519).</li>
                <li>8 de marzo: Día Internacional de la Mujer.</li>
                <li>10 de marzo: Tratado de Guadalupe Hidalgo (1848).</li>
                <li>13 de marzo: Fundación de la Universidad Autónoma de Puebla (1937).</li>
                <li>18 de marzo: Expropiación Petrolera (1938).</li>
                <li>21 de marzo: Natalicio de Benito Juárez (1806).</li>
                <li>22 de marzo: Día Mundial del Agua.</li>
                <li>28 de marzo: Fundación de Torreón, Coahuila (1893).</li>
                <li>30 de marzo: Día Mundial del Trastorno Bipolar.</li>
            </ul>
            <h2>Abril</h2>
            <ul>
                <li>7 de abril: Día Mundial de la Salud.</li>
                <li>10 de abril: Aniversario luctuoso de Emiliano Zapata (1919).</li>
                <li>13 de abril: Fundación de la Real Hacienda de Taxco (1529).</li>
                <li>15 de abril: Natalicio de Leonardo Bravo, insurgente mexicano (1764).</li>
                <li>18 de abril: Día Internacional de los Monumentos y Sitios.</li>
                <li>22 de abril: Aniversario de la Batalla de la Victoria (1847).</li>
                <li>23 de abril: Día Internacional del Libro.</li>
                <li>24 de abril: Fundación de la Villa de San Luis Potosí (1592).</li>
                <li>30 de abril: Día del Niño.</li>
            </ul>
            <h2>Mayo</h2>
            <ul>
                <li>1 de mayo: Día Internacional del Trabajo.</li>
                <li>3 de mayo: Día de la Santa Cruz y de los trabajadores de la construcción.</li>
                <li>5 de mayo: Aniversario de la Batalla de Puebla (1862).</li>
                <li>7 de mayo: Fundación del Instituto Politécnico Nacional (1936).</li>
                <li>8 de mayo: Natalicio de Miguel Hidalgo y Costilla (1753).</li>
                <li>10 de mayo: Día de las Madres.</li>
                <li>15 de mayo: Día del Maestro.</li>
                <li>17 de mayo: Día Mundial de las Telecomunicaciones.</li>
                <li>18 de mayo: Día Internacional de los Museos.</li>
                <li>23 de mayo: Aniversario de la muerte de Rubén Jaramillo, líder agrarista (1962).</li>
            </ul>
            <h2>Junio</h2>
            <ul>
                <li>1 de junio: Día de la Marina.</li>
                <li>5 de junio: Día Mundial del Medio Ambiente.</li>
                <li>7 de junio: Día de la Libertad de Expresión en México.</li>
                <li>10 de junio: Jueves de Corpus o “Halconazo” (1971).</li>
                <li>17 de junio: Día Mundial de Lucha contra la Desertificación y la Sequía.</li>
                <li>18 de junio: Fundación del Banco de México (1925).</li>
                <li>23 de junio: Día Internacional de las Viudas.</li>
                <li>24 de junio: Día Internacional contra el Trabajo Infantil.</li>
                <li>29 de junio: Día de San Pedro y San Pablo.</li>
            </ul>
            <h2>Julio</h2>
            <ul>
                <li>6 de julio: Fallecimiento de Frida Kahlo (1954).</li>
                <li>11 de julio: Día Mundial de la Población.</li>
                <li>18 de julio: Aniversario luctuoso de Benito Juárez (1872).</li>
                <li>19 de julio: Natalicio de Francisco Villa (1878).</li>
                <li>30 de julio: Fusilamiento de Miguel Hidalgo y Costilla (1811).</li>
            </ul>
        </div>
    </div>
);

export default Efemerides;
