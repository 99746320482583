import React from 'react';

const PrivacyPolicy = () => (
  <div style={{ backgroundColor: '#f0f0f0', color: '#333', padding: '20px' }}>
    <h1>Política de Privacidad</h1>
    <p>
      En esta sección, detallamos cómo recopilamos, usamos y protegemos su información personal.
    </p>
    <h2>Información que recopilamos</h2>
    <p>
      Recopilamos información personal que usted nos proporciona al registrarse en nuestro sitio, como su nombre, dirección de correo electrónico y otra información relevante.
    </p>
    <h2>Uso de la información</h2>
    <p>
      Utilizamos su información para mejorar nuestros servicios, comunicarnos con usted y personalizar su experiencia en nuestro sitio.
    </p>
    <h2>Protección de la información</h2>
    <p>
      Implementamos medidas de seguridad para proteger su información personal contra accesos no autorizados y divulgación.
    </p>
    <h2>Cambios en nuestra política</h2>
    <p>
      Nos reservamos el derecho de actualizar esta política de privacidad en cualquier momento. Le notificaremos sobre cambios significativos a través de nuestro sitio.
    </p>
  </div>
);

export default PrivacyPolicy;